import React, { useCallback, useEffect, useState, useRef } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import { GameOver } from "../GameOver/GameOver";
import { HUD } from "../HUD/HUD";
import { PausePanel } from "../PausePanel/PausePanel";
import { Onboarding } from "../Onboarding/Onboarding";
import { FloatingText } from "../FloatingText/FloatingText";
import Bowser from "bowser";

import StartBackground from "../Onboarding/StartBackground";

import VideoBroadcast from '../CameraComponent/VideoBroadcast';
import StartButtons from '../StartButtons/StartButtons';
import PhotoModal from '../PhotoModal/PhotoModal';

import "./StoryButton.css"
import HisWidget from '../HisWidget/HisWidget';
import AudioPlayer from '../AudioPlayer/AudioPlayer';
import AudioGuide from '../AudioGuide/AudioGuide';
import story_icon from './info_button.png';





let floatingTextTimer = 600;
let timeoutID = undefined;
export function UnityContainer() {
    const [startAudioGuide, setStartAudioGuide] = useState(false);
    const [playAudioGuide, setPlayAudioGuide] = useState(true);
    const [orientationPermissionGranted, setOrientationPermissionGranted] = useState(null);
    const [cameraPermissionGranted, setCameraPermissionGranted] = useState(null);
    //const [permissionGranted, setPermissionGranted] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenTwo, setIsModalOpenTwo] = useState(false);

    const [isWidgetOpen, setIsWidgetOpen] = useState(false);
    //const [startBroadcast, setStartBroadcast] = useState(false);
    const [playAudio, setPlayAudio] = useState(false);

    const [isGameOver, setIsGameOver] = useState(false);
    const [score, setScore] = useState(0); 
    const [isPause, setPause] = useState(true);
    const [isShowOnboarding, setOnboarding] = useState(true);
    const [floatingTexts, updateFloatingTexts] = useState([]);
    const { unityProvider, sendMessage, addEventListener, removeEventListener, isLoaded } =
        useUnityContext({
            loaderUrl: "./build/Pilot_14.loader.js",
            dataUrl: "./build/Pilot_14.data.unityweb",
            frameworkUrl: "./build/Pilot_14.framework.js.unityweb",
            codeUrl: "./build/Pilot_14.wasm.unityweb",
        });

    //const handleStartBroadcasting = (value) => {
    //    setStartBroadcast(value);
    //};
    const handleOrientationPermissionResult = (granted) => {
        setOrientationPermissionGranted(granted);
    };

    const handleCameraPermissionResult = (granted) => {
        setCameraPermissionGranted(granted);
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const handleOpenWidget = () => {
        setIsWidgetOpen(true);
    };

    const handleCloseWidget = () => {
        setIsWidgetOpen(false);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const closeModalTwo = () => {
        setIsModalOpenTwo(false);
    };
    const handleCallWeb = useCallback(() => {
        setIsModalOpen(true);
    });

    const handleCallWebTwo = useCallback(() => {
        setIsModalOpenTwo(true);
    });

    const handleGameOver = useCallback(() => {
        setIsGameOver(true);
        setPause(true);
    });
    const handleScoreUpdate = useCallback((score) => {
        setScore(score);
    });

    const handleFloatingText = useCallback((x, y, text) => {
        clearTimeout(timeoutID);
        timeoutID = setTimeout(() => updateFloatingTexts([]), floatingTextTimer);
        if (floatingTexts.length > 10) {
            updateFloatingTexts([]);
        }
        updateFloatingTexts(arr => [...arr, <FloatingText x={x} y={y} text={text} />]);
    });


    const handleStartAudioGuide = () => {
        setTimeout(() => {
            setStartAudioGuide(true);
        }, 23000); // 15 seconds delay
    };

    const handleAudioPlay = () => {
        setPlayAudioGuide(true);
    };

    const handleAudioPause = () => {
        setPlayAudioGuide(false);
    };




    //const handlePermissionResult = (granted) => {
    //    setPermissionGranted(granted);
    //}
    useEffect(() => {
        addEventListener("CallWeb", handleCallWeb);
        return () => {
            removeEventListener("CallWeb", handleCallWeb);
        };
    }, [addEventListener, removeEventListener, handleCallWeb]);

    useEffect(() => {
        addEventListener("CallWebTwo", handleCallWebTwo);
        return () => {
            removeEventListener("CallWebTwo", handleCallWebTwo);
        };
    }, [addEventListener, removeEventListener, handleCallWebTwo]);

    useEffect(() => {
        setPlayAudio(!isShowOnboarding);
    }, [isShowOnboarding]);

    useEffect(() => {
        addEventListener("OnGameOver", handleGameOver);
        return () => {
            removeEventListener("OnGameOver", handleGameOver);
        };
    }, [addEventListener, removeEventListener, handleGameOver]);
    useEffect(() => {
        addEventListener("OnScoreUpdate", handleScoreUpdate);
        return () => {
            removeEventListener("OnScoreUpdate", handleScoreUpdate);
        };
    }, [addEventListener, removeEventListener, handleScoreUpdate]);

    useEffect(() => {
        addEventListener("OnFloatingText", handleFloatingText);
        return () => {
            removeEventListener("OnFloatingText", handleFloatingText);
        };
    }, [addEventListener, removeEventListener, handleFloatingText]);


    function cameraPermissionTrue() {
        //function
    }

    function callWebFunction() {
       setIsModalOpen(true);
        //Function called from Unity!
    }

    function motionPermissionTrue() {
        sendMessage("ReactCallManager", "WakeUpSensors");
    }

    function handlePauseButton() {
        sendMessage("ReactEventsHandler", "Pause");
        setPause(true);
    }
    function handleResumeButton() {
        sendMessage("ReactEventsHandler", "Resume");
        setPause(false);
    }
    function handleRestartButton() {
        sendMessage("ReactEventsHandler", "Restart");
        setIsGameOver(false);
        setScore(0);
        setTimeout(() => {
            handleResumeButton();
        }, 100)

    }
    function handleStartButton() {
        handleResumeButton();
        setOnboarding(false);
        handleStartAudioGuide();

        setTimeout(() => {
            handleResumeButton();
            setOnboarding(false);
            handleStartAudioGuide();
        }, 1000);
    }






    return (<div>
        <Unity unityProvider={unityProvider} style={{
            width: "100vw", height: "100vh", overflow: "hidden", position: "fixed", left: "0",
            top: "0", zIndex: 1 }} />
        <HUD score={score} pauseCallback={handlePauseButton} />
        {isGameOver ? <GameOver score={score} restartCallback={handleRestartButton} /> : ""}
        {isPause ? <PausePanel resumeCallback={handleResumeButton} /> : ""}
        {floatingTexts}
        {(orientationPermissionGranted && cameraPermissionGranted) && isShowOnboarding ? <Onboarding isActive={!isLoaded} startCallback={handleStartButton} /> : ""}
        {isShowOnboarding ? <StartBackground /> : ""}

        <VideoBroadcast cameraPermissionGranted={cameraPermissionGranted} />

        {playAudio && < AudioPlayer playAudio={playAudio} />}

        {startAudioGuide && (
            <AudioGuide
                playAudioGuide={playAudioGuide}
                onAudioPlay={handleAudioPlay}
                onAudioPause={handleAudioPause}
            />
        )}

        <PhotoModal isOpen={isModalOpen} onClose={closeModal} photoUrl="https://add.pics/images/2024/06/03/Photo_one.png" />
        <PhotoModal isOpen={isModalOpenTwo} onClose={closeModalTwo} photoUrl="https://add.pics/images/2024/06/03/Photo_two.png" />

        {!(orientationPermissionGranted && cameraPermissionGranted) && 
            <StartButtons
                onOrientationPermissionResult={handleOrientationPermissionResult}
                onCameraPermissionResult={handleCameraPermissionResult}
            />}


        <HisWidget isOpen={isWidgetOpen} onClose={handleCloseWidget} photoUrl="https://add.pics/images/2024/06/03/Photo_one.png" photoUrlTwo="https://add.pics/images/2024/06/03/Photo_two.png"/>
        <button className="styled-button" onClick={handleOpenWidget}><img src={story_icon} alt="icon" className="button-icon" /></button>

        {orientationPermissionGranted === true && motionPermissionTrue()}


        </div>);

}